import Vue from 'vue'
import App from './App.vue'
import store from './store'

import '@/assets/styles/global.css'
import 'element-ui/lib/theme-chalk/pagination.css'
import 'element-ui/lib/theme-chalk/base.css'
import service from '@/util/request'

document.addEventListener('DOMContentLoaded', function () {
  const $dom = document.createElement('div')
  $dom.style = 'font-size:10px;'
  document.body.appendChild($dom)
  // 计算出放大后的字体
  const scaledFontSize = parseInt(window.getComputedStyle($dom, null).getPropertyValue('font-size'))
  document.body.removeChild($dom)
  // 计算原字体和放大后字体的比例
  const scaleFactor = 10 / scaledFontSize

  // 取html元素的字体大小
  // 注意，这个大小也经过缩放了
  // 所以下方计算的时候 *scaledFontSize是原来的html字体大小
  // 再次 *scaledFontSize才是我们要设置的大小
  const originRootFontSize = parseInt(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'))
  document.documentElement.style.fontSize = originRootFontSize * scaleFactor * scaleFactor + 'px'
})

Vue.config.productionTip = false
Vue.prototype.$req = service

Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = '漫调e空间'
  }
})
new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
