<template>
  <div class="app-container" id="漫调资讯">
    <div class="home-bg bg-image"></div>
    <div class="business-boxing" id="资讯">
      <div class="business-border" id="活动">
        <information-center></information-center>
      </div>
      <div class="article-container">
        <div v-if="tableData.length > 0">
          <div class="title-container" v-for="item in tableData" :key="item.id" @click="linkTo(item)">
            <div class="title">{{item.xTitle}}</div>
            <div class="time">{{item.xTime}}</div>
          </div>
        </div>
        <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%">
          <div class="empty-text">暂无数据</div>
        </div>
        <div class="pagination-div">
          <pagination :small="isPhone === 'phone'" hide-on-single-page background layout="prev, pager, next" :total="totalNum" :page-size="10" @current-change="getValue"></pagination>
        </div>
      </div>
<!--      <div class="images-div" id="动态">-->
<!--        <div class="images">-->
<!--          <div class="menus">-->
<!--            <div v-for="item in menus" :key="item.id" class="menu-item-div" @click="checkMenu(item)" :class="activeName === item.name ? 'active' : ''">-->
<!--              <div class="menu-line"></div>-->
<!--              <div class="menu-text">-->
<!--                <div>{{item.name}}</div>-->
<!--                <div>{{item.english}}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="content-container">-->
<!--            <div class="loop-img">-->
<!--              <img src="@/assets/images/information/loop1.jpg" alt="" />-->
<!--            </div>-->
<!--            <div class="article-container">-->
<!--              <div v-if="tableData.length > 0">-->
<!--                <div class="title-container" v-for="item in tableData" :key="item.id" @click="linkTo(item)">-->
<!--                  <div class="title">{{item.xTitle}}</div>-->
<!--                  <div class="time">{{item.xTime}}</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div v-else style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%">-->
<!--                <div class="empty-text">暂无数据</div>-->
<!--              </div>-->
<!--              <div class="pagination-div">-->
<!--                <pagination :small="isPhone === 'phone'" hide-on-single-page background layout="prev, pager, next" :total="totalNum" :page-size="5" @current-change="getValue"></pagination>-->
<!--              </div>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="article-container">&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="title-container">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="title">渝北区税务局领导一行莅临漫调e空间考察交流</div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="time">2024-10-31</div>&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="article-text">&ndash;&gt;-->
<!--&lt;!&ndash;                2024年10月30日下午，重庆市渝北区税务局程羿副局长一行莅临漫调e空间进行考察交流。公司总经理辛赞洋、运营总监龚燕一行热情接待。&ndash;&gt;-->
<!--&lt;!&ndash;                漫调e空间总经理辛赞洋首先引导程羿副局长一行参观漫调e空间展厅，&ndash;&gt;-->
<!--&lt;!&ndash;                逐步向来访嘉宾介绍漫调e空间的发展历程、经营现状、数字化转型等内容，随后双方进行座谈交流...&ndash;&gt;-->
<!--&lt;!&ndash;              </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import { handleScrollToAnchor, isMobile } from '@/util/tool'
import { informationTypes } from '@/common-data/information'
import InformationCenter from '@/components/common/information-center.vue'
import { Pagination } from 'element-ui'

export default {
  components: { InformationCenter, Pagination },
  name: 'information',
  data () {
    return {
      activeName: '资讯',
      hasMounted: false,
      tableData: [],
      isPhone: '',
      menus: informationTypes,
      totalNum: undefined,
      currentPage: 1,
      queryParams: {
        current: 1,
        size: 10
      }
    }
  },
  props: {
    jumpName: {
      type: String
    }
  },
  watch: {
    jumpName: {
      handler (val) {
        if (this.hasMounted && val) this.handleScrollToAnchor(val)
      },
      immediate: true
    }
  },
  created () {
    this.isPhone = this.isMobile()
    this.getData()
  },
  mounted () {
    this.hasMounted = true
    if (this.jumpName) {
      this.handleScrollToAnchor(this.jumpName)
    }
  },
  methods: {
    isMobile,
    async getData (params) {
      const { current, size } = this.queryParams
      try {
        const res = await this.$req.get(`/app/OffwebNews/newlist?current=${current}&size=${size}`, { params })
        this.tableData = res.data.content
        this.totalNum = res.data.totalElements
      } catch (error) {
        alert(error)
      }
    },
    handleScrollToAnchor,
    checkMenu (item) {
      this.activeName = item.name
      const classifyId = item.id
      this.getData({ classifyId })
    },
    async getValue (val) {
      this.queryParams.current = val
      await this.getData()
    },
    linkTo (item) {
      window.open(item.xUrl, '_blank')
    }
  }
}
</script>
<style scoped lang="less">
@import "@/assets/styles/common.css";
.bg-image{
  background: url('https://imandiao.oss-cn-beijing.aliyuncs.com/website/images/information/background.png') no-repeat center;
  background-size: cover;
  object-fit: cover;
}

.business-boxing{
  background:rgba(256, 256, 256, 1);
}
.business-border{
  padding-top: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .business-border{
    padding-top: 15px;
  }
}

.active{
  opacity: 0.5;
}

.images-div{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .images-div{
    margin-top: 20px;
    width: 100%;
  }
}

.images {
  margin: 60px 0 60px 0;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .images {
    width: 100%;
    margin: 20px 0 40px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .images {
    margin: 60px 0 60px 0;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.menus{
  width: 197px;
  height: 736px;
  background: #E60012;
}

@media (max-width: 768px) {
  .menus{
    width: 85px;
    height: 368px;
  }
}

.menu-item-div{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 141px;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 18px;
}

@media (max-width: 768px) {
  .menu-item-div{
    font-size: 6px;
    color: #FFFFFF;
    line-height: 9px;
    height: 70px;
  }
}

.menu-text{
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .menu-text{
    margin-right: 5px;
    font-size: 0.5rem;
  }
}

.menu-line{
  margin-left: 30px;
  width: 22px;
  height: 1px;
  background-color: #F1F2F5;
}

@media (max-width: 768px) {
  .menu-line{
    margin-left: 10px;
    width: 10px;
    height: 2px;
  }
}

.content-container{
  height: 736px;
  background: #F1F2F5;
}

@media (max-width: 768px) {
  .content-container{
    height: 368px;
  }
}

.loop-img{
  height: 449px;
  width: 100%;
}

.loop-img img{
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .loop-img{
    height: 210px;
  }
}

.article-container{
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 768px) {
  .article-container{
    margin-top: 20px;
  }
}

.title{
  width: 1000px;
  font-weight: 500;
  font-size: 1.125rem;
  color: #383838;
  line-height: 28px;
  overflow: hidden; /* 隐藏溢出的内容 */
  white-space: nowrap; /* 保持文本在一行内显示 */
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .title{
    width: 300px;
    font-size: 0.625rem;
    line-height: 0.8rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .title{
    width: 660px;
    font-size: 20px;
    line-height: 20px;
  }
}

.time{
  padding: 0 40px 0 0;
  font-weight: 400;
  font-size: 1.125rem;
  color: #383838;
  line-height: 1.8rem;
}

@media (max-width: 768px) {
  .time{
    font-size: 0.625rem;
    line-height: 0.9rem;
    padding: 0 10px 0 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .time{
    font-size: 18px;
    line-height: 15px;
    padding: 0 10px 0 0;
  }
}

.title-container{
  padding: 18px 0 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  cursor: pointer;
}

@media (max-width: 768px) {
  .title-container{
    padding: 10px 0 0 8px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .title-container{
    padding: 24px 0 0 8px;
  }
}

.article-text{
  padding: 36px 18px 0 18px;
  font-weight: 400;
  font-size: 1.25rem;
  color: #383838;
  line-height: 35px;
}

@media (max-width: 768px) {
  .article-text{
    padding: 10px 0 0 8px;
    font-size: 0.625rem;
    line-height: 0.7rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 976px) {
  .article-text{
    padding: 18px 0 0 8px;
    font-size: 18px;
    color: #383838;
    line-height: 35px;
  }
}

.pagination-div{
  display: flex;
  justify-items: end;
  align-items: end;
  margin: 40px 0 10px 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f5f5f5;
  color: #409EFF;
}

::v-deep .el-pager li{
  font-size: 1rem;
}

.empty-text{
  font-size: 1rem;
  color: #999999;
}

@media (max-width: 768px) {
  .pagination-div{
    display: flex;
    flex-direction: column;
    justify-items: end;
    align-items: center;
    margin-top: 15px;
  }
  ::v-deep .el-pagination.is-background.el-pagination--small .el-pager li{
    margin: 0;
    padding: 0 1.5px;
  }

  ::v-deep .el-pager li{
    font-size: 0.5rem;
  }
  .empty-text{
    font-size: 0.8rem;
    color: #999999
  }
}

.title:hover{
  text-decoration: underline;
}

</style>
