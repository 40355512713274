<template>
  <div class="app-container" style="position: relative;" v-title>
    <Header v-if="requestType === 'computer'" :active-btn="activeName" @checkSuccess="checkMenu"></Header>
    <PhoneHeader v-else-if="requestType === 'phone'" @checkSuccess="checkMenu"></PhoneHeader>
    <component :is="checkComponent" :jumpName="jumpName"></component>
    <artificial-intelligence></artificial-intelligence>
    <Footer @checkSuccess="checkMenu"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/foot/footer.vue'
import Home from '@/components/content/home.vue'
import AppInfo from '@/components/content/appInfo.vue'
import Header from '@/components/header/header.vue'
import ServiceInfo from '@/components/content/service-info.vue'
import AboutUs from '@/components/content/about-us.vue'
import Park from '@/components/content/park.vue'
import Information from '@/components/content/information.vue'
import PhoneHeader from '@/components/header/phone-header.vue'
import ArtificialIntelligence from '@/components/common/Artificial-intelligence.vue'
import { isMobile } from '@/util/tool'
export default {
  name: 'officialIndex',
  components: { Header, Footer, Home, AppInfo, ServiceInfo, AboutUs, Park, Information, PhoneHeader, ArtificialIntelligence },
  data () {
    return {
      checkComponent: 'Home',
      choice: {
        index: 'Home',
        app: 'AppInfo',
        service: 'ServiceInfo',
        aboutUs: 'aboutUs',
        park: 'Park',
        information: 'Information'
      },
      requestType: '',
      jumpName: '',
      activeName: '首页'
    }
  },
  created () {
    this.requestType = this.isMobile()
  },
  methods: {
    isMobile,
    checkMenu (item) {
      this.activeName = item.parentName || item.name
      this.jumpName = item.name
      const eName = item.parentEName || item.eName
      this.checkComponent = this.choice[eName]
    }
  }
}
</script>
